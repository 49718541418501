import React, { useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom"; 
import "../../styles/header.css";
import logoImage from '../../assets/all-images/white.png';
import logoImage1 from '../../assets/all-images/logo.png';

const navLinks = [
  {
    path: "/",
    display: "Home",
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/contact",
    display: "Contact",
  },
];

const Header = () => {
  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  return (
    <header className="header">
      {/* ============ header top ============ */}
      <div className="header__top d-none d-md-block">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left">
                <span>Need Help?</span>
                <span className="header__top__help">
                  <i className="ri-phone-fill"></i> +1-(844) 661-0254 <span className="d-none">tel:+18446610254</span>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* =============== header middle =========== */}
      <div className="header__middle d-none d-md-block">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <div className="logo">
                <h1>
                  <Link to="/" className="d-flex align-items-center gap-2">
                    <img src={logoImage1} alt="Next Gen Auto Parts Logo" className="logo-image" />
                    <span>
                      Next Gen Auto <br /> Parts
                    </span>
                  </Link>
                </h1>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-earth-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>United States</h4>
                  <h6>30 N Gould St Ste R Sheridan, Wyoming, 82801</h6>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Monday to Sunday</h4>
                  <h6>10am - 7pm</h6>
                </div>
              </div>
            </Col>

            <Col lg="2" md="3" sm="0" className="d-flex align-items-center justify-content-end">
              <button className="header__btn btn">
                <a href="tel:+18446610254">
                  <i className="ri-phone-line"></i> Call Us Toll Free Number <span className="d-none">tel:+18446610254</span>
                </a>
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ========== main navigation =========== */}
      <div className="main__navbar d-none d-md-block">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <div className="navigation">
              <div className="menu">
                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) => 
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                  >
                    {item.display}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* ========== mobile navigation =========== */}
      <div className="mobile__navbar d-block d-md-none">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <div className="mobile__menu d-flex align-items-center">
              <i className="ri-menu-line" onClick={toggleMenu}></i>
            </div>
            
            <div className="logo-center d-flex align-items-center">
              <img src={logoImage} alt="Next Gen Auto Parts Logo" className="mobile-logo" />
              <span className="logo-text">Next Gen Auto Parts</span>
            </div>

            <a href="tel:+18446610254" className="blink">Call to Order <span className="d-none">tel:+18446610254</span></a>
          </div>

          <div className="navigation" ref={menuRef}>
            <div className="menu d-flex flex-column">
              {navLinks.map((item, index) => (
                <NavLink
                  to={item.path}
                  className="nav__item d-none"
                  key={index}
                  onClick={toggleMenu}
                >
                  {item.display}
                </NavLink>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
