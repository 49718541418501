import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/cars-img/bmw-offer.png";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "40px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title">Welcome to Next Gen Auto Parts</h2>
              <p className="section__description">
                At Next Gen Auto Parts, we are your all-in-one resource for high-quality, affordable used OEM auto parts and accessories. We have served countless clients across the world, working tirelessly to find the perfect parts for your vehicle.
              </p>
              <p className="section__description">
                Our goal is to locate the correct part at an inexpensive price, close to your home or business. We understand the importance of your vehicle's reliability, which is why we offer a rigorous quality control process to ensure that each part meets our high standards.
              </p>
              <p className="section__description">
                We also provide a guarantee on all our parts, giving you peace of mind and confidence in your purchase. Our dedicated team of sales professionals is available 365 days a year, ready to help you place new orders from 6am to 9pm CST, seven days a week.
              </p>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i className="ri-checkbox-circle-line"></i> High-quality used OEM parts
                </p>
                <p className="section__description d-flex align-items-center gap-2">
                  <i className="ri-checkbox-circle-line"></i> Rigorous quality control
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i className="ri-checkbox-circle-line"></i> Affordable prices
                </p>
                <p className="section__description d-flex align-items-center gap-2">
                  <i className="ri-checkbox-circle-line"></i> Guaranteed satisfaction
                </p>
              </div>
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="About us" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
