import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const settings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="hero__slider"> 
      <div className="slider__item slider__item-01 mt0">
        <Container>
          <div className="slider__content ">
            
            <h1 className="text-light mb-4">Discover Top-Quality, Affordable Auto Parts</h1>
            <h4 className="text-light mb-3">Don't miss out on our limited-time offer!<span> Call us now to get an extra 15% off on all high-quality</span> , affordable second-hand auto parts. Experience exceptional service and unbeatable value with Next Gen Auto Parts.</h4>
            <button className="btn reserve__btn mt-4">
      <a href="tel:+18446610254">
        Call Us Toll Free <span className="d-none">tel:+18446610254</span> 
      </a>
    </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-02 mt0">
        <Container>
          <div className="slider__content ">
          <h1 className="text-light mb-4">Discover Top-Quality, Affordable Auto Parts</h1>
            <h4 className="text-light mb-3">Don't miss out on our limited-time offer!<span> Call us now to get an extra 15% off on all high-quality</span> , affordable second-hand auto parts. Experience exceptional service and unbeatable value with Next Gen Auto Parts.</h4>

            <button className="btn reserve__btn mt-4">
      <a href="tel:+18446610254">
        Call Us Toll Free <span className="d-none">tel:+18446610254</span>
      </a>
    </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-03 mt0">
        <Container>
          <div className="slider__content ">
          <h1 className="text-light mb-4">Discover Top-Quality, Affordable Auto Parts</h1>
            <h4 className="text-light mb-3">Don't miss out on our limited-time offer!<span> Call us now to get an extra 15% off on all high-quality</span> , affordable second-hand auto parts. Experience exceptional service and unbeatable value with Next Gen Auto Parts.</h4>

            <button className="btn reserve__btn mt-4">
      <a href="tel:+18446610254">
        Call Us Toll Free <span className="d-none">tel:+18446610254</span>
      </a>
    </button>
          </div>
        </Container>
      </div>
    </Slider>
  );
};

export default HeroSlider;
