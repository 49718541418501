import React, { useState } from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/footer.css";
import Logo1 from '../../assets/all-images/logo-white.png';

const quickLinks = [
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/privacy-policy",
    display: "Privacy Policy",
  },
  {
    path: "/terms-conditions",
    display: "Terms & Conditions",
  },
  
  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const date = new Date();
  const year = date.getFullYear();

  const handleNewsletterSubmit = (event) => {
    event.preventDefault();
    // Perform validation or other actions here
    navigate('/thankyou-subscription');
  };

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className="d-flex align-items-center gap-2">
                  <img src={Logo1} alt="Next Gen Auto Parts Logo" className="logo-image" />
                  <span>
                    Next Gen Auto <br /> Parts
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
              At Next Gen Auto Parts, we are dedicated to providing high-quality, affordable used OEM auto parts and accessories. Our mission is to help you find the correct part at an inexpensive price, close to your home or business. With our extensive inventory and excellent customer service, we ensure your vehicle is back on the road quickly and efficiently.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">30 N Gould St Ste R Sheridan, WY 82801</p>
              <p className="office__info">Phone: <a href="tel:+18446610254">+1 (844) 661-0254</a></p>
              <p className="office__info">Email: info.nextgenautosparts.com</p>
              <p className="office__info">Office Time: 10am - 7pm</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description">Subscribe to our newsletter</p>
              <form className="newsletter" onSubmit={handleNewsletterSubmit}>
                <input 
                  type="email" 
                  placeholder="Email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
                <button type="submit" className="newsletter__btn">
                  <i className="ri-send-plane-line"></i>
                </button>
              </form>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i className="ri-copyright-line"></i>Copyright {year}, Developed by
                Next Gen Auto Parts. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
