import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push } from "firebase/database";

import "../styles/contact.css";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDNRT1D6PovDsUXSP1a6paXmyWoJFqAu8fI",
  authDomain: "contactform-2afa5.firebaseapp.com",
  databaseURL: "https://contactform-2afa5-default-rtdb.firebaseio.com",
  projectId: "contactform-2afa5",
  storageBucket: "contactform-2afa5.appspot.com",
  messagingSenderId: "638407937028",
  appId: "1:638407937028:web:8ffa8f9eb1449dc5073951"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const submissionData = {
      name,
      email,
      message,
      dateTime: new Date().toISOString(),
    };

    push(ref(database, 'contacts'), submissionData)
      .then(() => {
        setSuccessMessage('Message sent successfully! We will get back to you within 2 hours');
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error("Error submitting data: ", error);
      });
  };

  return (
    <Helmet title="Contact">
      <CommonSection title="Contact" />
      <section>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Get In Touch</h6>

              <Form onSubmit={handleSubmit}>
                <FormGroup className="contact__form">
                  <Input 
                    placeholder="Your Name" 
                    type="text" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required 
                  />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input 
                    placeholder="Email" 
                    type="email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required 
                  />
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    className="textarea"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </FormGroup>

                <button className="contact__btn" type="submit">
                  Send Message
                </button>
              </Form>
              {successMessage && (
                <p className="success-message">{successMessage}</p>
              )}
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>
                <p className="section__description mb-0">
                30 N Gould St Ste R Sheridan, WY 8280
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+1 (844) 661-0254</p>
                </div>

                <div className=" d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">info.nextgenautosparts.com</p>
                </div>

                {/* <h6 className="fw-bold mt-4">Follow Us</h6>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="social__link-icon"
                    >
                      <i className={item.icon}></i>
                    </Link>
                  ))}
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
