// import all images from assets/images directory
import img01 from "../all-images/cars-img/Mirrors.png";
import img02 from "../all-images/cars-img/Headlights.png";
import img03 from "../all-images/cars-img/Engine.png";
import img04 from "../all-images/cars-img/Brakes.png";
import img05 from "../all-images/cars-img/Suspension.png";
import img06 from "../all-images/cars-img/Interior.png";
import img07 from "../all-images/cars-img/toyota-offer-2.png";
import img08 from "../all-images/cars-img/mercedes-offer.png";

const carData = [
  {
    id: 1,
    // brand: "Auto Body Parts & Mirrors",
    // rating: 112,
    carName: "Auto Body Parts & Mirrors",
    imgUrl: img01,
    // model: "Model 3",
    // price: 50,
    // speed: "20kmpl",
    // gps: "GPS Navigation",
    // seatType: "Heated seats",
    // automatic: "Automatic", 
    description:
      " Our extensive range of auto body parts and mirrors ensures that you find the perfect match for your vehicle. From fenders and bumpers to side mirrors and grilles, we provide high-quality parts that enhance the look and functionality of your car.",
  },

  {
    id: 2,
    carName: "Headlights & Lighting",
    imgUrl: img02,
    description:
      " Illuminate your path with our top-notch headlights and lighting solutions. We offer a variety of headlamps, tail lights, fog lights, and interior lighting options to ensure your vehicle is well-lit and safe for night driving.",
  },

  {
    id: 3,
    carName: "Engine & Drivetrain",
    imgUrl: img03,
    description:
      " Keep your vehicle's heart running smoothly with our engine and drivetrain components. From complete engines to transmission parts, we have everything you need to maintain optimal performance and efficiency.",
  },

  {
    id: 4,
    carName: "Brakes",
    imgUrl: img04,
    description:
      " Ensure your safety with our premium brake parts. We offer a wide selection of brake pads, rotors, calipers, and complete brake kits to keep your vehicle's braking system in top condition.",
  },

  {
    id: 5,
    carName: "Suspension & Steering",
    imgUrl: img05,
    description:
      " Experience smooth handling and ride comfort with our suspension and steering components. Our range includes shock absorbers, struts, control arms, and steering racks designed to provide excellent performance and durability.",
  },

  {
    id: 6,
    carName: "Interior Accessories",
    imgUrl: img06,
    description:
      " Upgrade your vehicle's interior with our high-quality accessories. From seat covers and floor mats to steering wheel covers and dashboard trims, we have everything to enhance your driving experience and comfort.",
  },

  {
    id: 7,
    brand: "Audi",
    rating: 82,
    carName: "Audi Fiesta",
    imgUrl: img07,
    model: "Model 3",
    price: 50,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },

  {
    id: 8,
    brand: "Colorado",
    rating: 52,
    carName: "Rolls Royce Colorado",
    imgUrl: img08,
    model: "Model 3",
    price: 50,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },
];

export default carData;
