import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import "../styles/thankyou.css";

const ThankYou = () => {
  return (
    <Helmet title="Thank You">
      <CommonSection title="Thank You" />
      <section className="thankyou__section">
        <Container>
          <Row>
            <Col lg="12">
              <div className="thankyou__content">
                <h2 className="section__title">Thank You for Your Submission!</h2>
                <p className="section__description">
                  We have received your message and will get back to you shortly. If you have any further questions, please feel free to contact us at:
                </p>
                <p className="section__description">
                  Email: info.nextgenautosparts.com
                  <br />
                  Phone: +1 (844) 661-0254
                  <br />
                  Address: 30 N Gould St Ste R Sheridan, WY 82801
                </p>
                <p className="section__description">
                  We appreciate your interest in Next Gen Auto Parts and look forward to serving you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default ThankYou;
