import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import "../styles/privacy-policy.css";

const PrivacyPolicy = () => {
  return (
    <Helmet title="Privacy Policy">
      <CommonSection title="Privacy Policy" />
      <section className="privacy__policy-section">
        <Container>
          <Row>
            <Col lg="12">
              <div className="privacy__policy-content">
                <h2 className="section__title">Privacy Policy</h2>
                <p className="section__description">
                  At Next Gen Auto Parts, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website or purchase our products.
                </p>

                <h3 className="section__subtitle">Information We Collect</h3>
                <p className="section__description">
                  We collect various types of information, including:
                </p>
                <ul className="section__description">
                  <li>Personal identification information (Name, email address, phone number, etc.)</li>
                  <li>Payment information (Credit card details, billing address, etc.)</li>
                  <li>Purchase history and preferences</li>
                  <li>Technical data (IP address, browser type, device information, etc.)</li>
                </ul>

                <h3 className="section__subtitle">How We Use Your Information</h3>
                <p className="section__description">
                  We use the information we collect to:
                </p>
                <ul className="section__description">
                  <li>Process and fulfill your orders</li>
                  <li>Improve our website and customer service</li>
                  <li>Send promotional emails and updates</li>
                  <li>Respond to your inquiries and provide support</li>
                  <li>Comply with legal requirements and prevent fraud</li>
                </ul>

                <h3 className="section__subtitle">Information Sharing</h3>
                <p className="section__description">
                  We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:
                </p>
                <ul className="section__description">
                  <li>With trusted partners who assist us in operating our website and conducting our business</li>
                  <li>When we believe release is appropriate to comply with the law or protect ours or others' rights, property, or safety</li>
                  <li>In connection with a merger, acquisition, or sale of all or a portion of our assets</li>
                </ul>

                <h3 className="section__subtitle">Data Security</h3>
                <p className="section__description">
                  We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
                </p>

                <h3 className="section__subtitle">Your Rights</h3>
                <p className="section__description">
                  You have the right to:
                </p>
                <ul className="section__description">
                  <li>Access the personal information we hold about you</li>
                  <li>Request the correction of inaccurate information</li>
                  <li>Request the deletion of your personal information</li>
                  <li>Opt out of receiving marketing communications</li>
                </ul>

                <h3 className="section__subtitle">Changes to This Privacy Policy</h3>
                <p className="section__description">
                  We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
                </p>

                <h3 className="section__subtitle">Contact Us</h3>
                <p className="section__description">
                  If you have any questions about this Privacy Policy, please contact us at:
                </p>
                <p className="section__description">
                  Email: info.nextgenautosparts.com
                  <br />
                  Phone: +1 (844) 661-0254
                  <br />
                  Address: 30 N Gould St Ste R Sheridan, WY 82801
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default PrivacyPolicy;
