import React from "react";
import "../../styles/become-driver.css";
import { Container, Row, Col } from "reactstrap";

import driverImg from "../../assets/all-images/toyota-offer-2.png";

const BecomeDriverSection = () => {
  return (
    <section className="become__driver">
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12" className="become__driver-img">
            <img src={driverImg} alt="" className="w-100" />
          </Col>

          <Col lg="6" md="6" sm="12">
            <h2 className="section__title become__driver-title">
            Best deal with purchase of Auto Parts
            </h2>

            <button className="btn become__driver-btn mt-4">
      <a href="tel:+18773134306" style={{ color: 'inherit', textDecoration: 'none' }}>
        Call Us Toll Free <span className="d-none">tel:+18446610254</span>
      </a>
    </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BecomeDriverSection;
