import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import "../styles/terms-conditions.css";

const TermsAndConditions = () => {
  return (
    <Helmet title="Terms & Conditions">
      <CommonSection title="Terms & Conditions" />
      <section className="terms__conditions-section">
        <Container>
          <Row>
            <Col lg="12">
              <div className="terms__conditions-content">
                <h2 className="section__title">Terms & Conditions</h2>
                <p className="section__description">
                  Welcome to Next Gen Auto Parts. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Next Gen Auto Parts if you do not agree to all the terms and conditions stated on this page.
                </p>

                <h3 className="section__subtitle">License</h3>
                <p className="section__description">
                  Unless otherwise stated, Next Gen Auto Parts and/or its licensors own the intellectual property rights for all material on Next Gen Auto Parts. All intellectual property rights are reserved. You may access this from Next Gen Auto Parts for your own personal use subjected to restrictions set in these terms and conditions.
                </p>

                <h3 className="section__subtitle">User Comments</h3>
                <p className="section__description">
                  Certain parts of this website offer the opportunity for users to post and exchange opinions and information in certain areas of the website. Next Gen Auto Parts does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Next Gen Auto Parts, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions.
                </p>

                <h3 className="section__subtitle">Hyperlinking to our Content</h3>
                <p className="section__description">
                  The following organizations may link to our website without prior written approval:
                </p>
                <ul className="section__description">
                  <li>Government agencies</li>
                  <li>Search engines</li>
                  <li>News organizations</li>
                  <li>Online directory distributors</li>
                  <li>System wide Accredited Businesses</li>
                </ul>

                <h3 className="section__subtitle">Content Liability</h3>
                <p className="section__description">
                  We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                </p>

                <h3 className="section__subtitle">Your Privacy</h3>
                <p className="section__description">
                  Please read our Privacy Policy.
                </p>

                <h3 className="section__subtitle">Reservation of Rights</h3>
                <p className="section__description">
                  We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                </p>

                <h3 className="section__subtitle">Removal of links from our website</h3>
                <p className="section__description">
                  If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
                </p>

                <h3 className="section__subtitle">Disclaimer</h3>
                <p className="section__description">
                  To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                </p>
                <ul className="section__description">
                  <li>Limit or exclude our or your liability for death or personal injury</li>
                  <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation</li>
                  <li>Limit any of our or your liabilities in any way that is not permitted under applicable law</li>
                  <li>Exclude any of our or your liabilities that may not be excluded under applicable law</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default TermsAndConditions;
