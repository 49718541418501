import React from "react";
import "../../styles/click-to-call-footer.css";

const ClickToCallFooter = () => {
  return (
    <div className="click-to-call-footer">
      <a href="tel:+18446610254" className="click-to-call-button">
        <i className="ri-phone-line"></i> Call Us Now & Get Extra 15% Off<span className="d-none">tel:+18446610254</span>
      </a>
    </div>
  );
};

export default ClickToCallFooter;
