import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

import ava01 from "../../assets/all-images/ava-1.jpg";
import ava02 from "../../assets/all-images/ava-2.jpg";
import ava03 from "../../assets/all-images/ava-3.jpg";
import ava04 from "../../assets/all-images/ava-4.jpg";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
          I was in desperate need of a rare engine part for my car. Next Gen Auto Parts not only found the part but also delivered it in record time. Their service is top-notch and their parts are of excellent quality.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava01} alt="Customer" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Michael Scott</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Next Gen Auto Parts helped me find affordable and high-quality brake parts for my vehicle. Their customer service is exceptional and they were very helpful in answering all my queries.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava02} alt="Customer" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Dwight Schrute</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          I purchased several interior accessories from Next Gen Auto Parts and I am extremely satisfied with the quality and price. Their products have given my car's interior a new look. Highly recommend their services!
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava03} alt="Customer" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Pam Beesly</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Finding the right parts for my vehicle has never been easier. Next Gen Auto Parts offers a wide range of high-quality parts and their delivery is very fast. I appreciate their excellent customer service and support.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava04} alt="Customer" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Jim Halpert</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonial;
