import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/services.css";

const services = [
  {
    icon: "ri-money-dollar-circle-line",
    title: "Buy With Confidence",
    description: "Shop with peace of mind knowing that our products are of high quality and thoroughly inspected to meet your expectations.",
  },
  {
    icon: "ri-arrow-go-back-line",
    title: "30 Day Returns",
    description: "If you're not satisfied with your purchase, take advantage of our 30-day return policy for a hassle-free return process.",
  },
  {
    icon: "ri-phone-line",
    title: "Call To Order",
    description: "Need help placing an order? Call our toll-free number +1 (844) 661-0254 and our friendly representatives will assist you promptly.",
  },
];

const Services = () => {
  return (
    <section className="services">
      <Container> 
        <Row>
          {services.map((service, index) => (
            <Col lg="4" md="6" sm="12" className="mb-4" key={index}>
              <div className="service-item d-flex align-items-center">
                <i className={`service-icon ${service.icon}`}></i>
                <div className="service-content">
                  <h5>{service.title}</h5>
                  <p>{service.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Services;
