import React, { useState } from "react";
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push } from "firebase/database";
import { useNavigate } from "react-router-dom";
import data from '../../data.json';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDNRT1D6PovDsUXSP1a6paXmyWoJFqAu8fI",
  authDomain: "contactform-2afa5.firebaseapp.com",
  databaseURL: "https://contactform-2afa5-default-rtdb.firebaseio.com",
  projectId: "contactform-2afa5",
  storageBucket: "contactform-2afa5.appspot.com",
  messagingSenderId: "638407937028",
  appId: "1:638407937028:web:8ffa8f9eb1449dc5073951"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Function to get current date and time in IST
const getCurrentISTDateTime = () => {
  const date = new Date();
  const options = { timeZone: 'Asia/Kolkata', hour12: false };
  const dateTimeString = date.toLocaleString('en-US', options).replace(/[/]/g, '-');
  return dateTimeString;
};

const BookingForm = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1); // State to manage form steps
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [selectedMake, setSelectedMake] = useState({ id: '', name: '' });
  const [selectedModel, setSelectedModel] = useState({ id: '', name: '' });
  const [selectedYear, setSelectedYear] = useState({ id: '', label: '' });
  const [selectedPart, setSelectedPart] = useState({ id: '', label: '' });
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  // Extract options from data.json
  const makes = data.find(table => table.name === 'tbl_make_model_list').data.filter(item => item.type === 'make');
  const models = data.find(table => table.name === 'tbl_make_model_list').data.filter(item => item.type === 'model' && item.parent_id === selectedMake.id);
  const years = data.find(table => table.name === 'tbl_master_data').data.filter(item => item.type === 'year');
  const parts = data.find(table => table.name === 'tbl_master_data').data.filter(item => item.type === 'part');

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const handleFirstSubmit = (event) => {
    event.preventDefault();
    setStep(2); // Move to the next step
  };

  const handleFinalSubmit = (event) => {
    event.preventDefault();

    const isEmailValid = validateEmail(email);
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);

    if (!isEmailValid) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }

    if (!isPhoneNumberValid) {
      setPhoneError('Please enter a valid 10-digit phone number.');
    } else {
      setPhoneError('');
    }

    if (isEmailValid && isPhoneNumberValid) {
      const submissionData = {
        name,
        phoneNumber,
        email,
        year: selectedYear.label,
        make: selectedMake.name,
        model: selectedModel.name,
        part: selectedPart.label,
        dateTime: getCurrentISTDateTime(),
      };

      push(ref(database, 'bookings'), submissionData)
        .then(() => navigate('/thankyou'))
        .catch((error) => console.error("Error submitting data: ", error));
    }
  };

  return (
    <div>
      {step === 1 ? (
        <Form onSubmit={handleFirstSubmit}>
          <FormGroup className="booking__form d-inline-block me-4 mb-4">
            <select 
              value={selectedYear.id}
              onChange={(e) => {
                const selectedOption = years.find(year => year.id === e.target.value);
                setSelectedYear({ id: selectedOption?.id || '', label: selectedOption?.label || '' });
              }}
              required 
            >
              <option value="">Select Year</option>
              {years.map(year => (
                <option key={year.id} value={year.id}>{year.label}</option>
              ))}
            </select>
          </FormGroup>

          <FormGroup className="booking__form d-inline-block ms-1 mb-4">
            <select 
              value={selectedMake.id}
              onChange={(e) => {
                const id = e.target.value;
                const make = makes.find(m => m.id === id);
                setSelectedMake({ id: make?.id || '', name: make?.name || '' });
              }}
              required 
            >
              <option value="">Select Make</option>
              {makes.map(make => (
                <option key={make.id} value={make.id}>{make.name}</option>
              ))}
            </select>
          </FormGroup>

          <FormGroup className="booking__form d-inline-block me-4 mb-4">
            <select 
              value={selectedModel.id}
              onChange={(e) => {
                const id = e.target.value;
                const model = models.find(m => m.id === id);
                setSelectedModel({ id: model?.id || '', name: model?.name || '' });
              }}
              required 
            >
              <option value="">Select Model</option>
              {models.map(model => (
                <option key={model.id} value={model.id}>{model.name}</option>
              ))}
            </select>
          </FormGroup>

          <FormGroup className="booking__form d-inline-block ms-1 mb-4">
            <select 
              value={selectedPart.id}
              onChange={(e) => {
                const id = e.target.value;
                const part = parts.find(p => p.id === id);
                setSelectedPart({ id: part?.id || '', label: part?.label || '' });
              }}
              required 
            >
              <option value="">Select Part</option>
              {parts.map(part => (
                <option key={part.id} value={part.id}>{part.label}</option>
              ))}
            </select>
          </FormGroup>

          <FormGroup>
            <button type="submit" className="btn booking__btn">Next</button>
          </FormGroup>
        </Form>
      ) : (
        <Form onSubmit={handleFinalSubmit}>
          <FormGroup className="booking__form d-inline-block me-4 mb-4">
            <input 
              type="text" 
              placeholder="Name" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              required 
            />
          </FormGroup>

          <FormGroup className="booking__form d-inline-block ms-1 mb-4">
            <input 
              type="number" 
              placeholder="Phone Number" 
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required 
            />
            {phoneError && <p className="error">{phoneError}</p>}
          </FormGroup>

          <FormGroup className="booking__form d-inline-block me-4 mb-4">
            <input 
              type="email" 
              placeholder="Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required 
            />
            {emailError && <p className="error">{emailError}</p>}
          </FormGroup>

          <FormGroup>
            <button type="submit" className="btn booking__btn">Submit</button>
          </FormGroup>
        </Form>
      )}
    </div>
  );
};

export default BookingForm;
