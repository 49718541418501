import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/reviews.css";

const reviewsData = [
  {
    icon: "ri-google-line",
    title: "Google Review",
    rating: 4.9,
  },
  {
    icon: "ri-shield-check-line",
    title: "Trusted Site Review",
    rating: 4.9,
  },
  {
    icon: "ri-star-line",
    title: "Trustpilot Review",
    rating: 4.8,
  },
];

const totalReviews = 678956;
const averageRating = 4.8;

const Reviews = () => {
  return (
    <section className="reviews">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="section-title">Customer Reviews</h2>
          </Col>
        </Row>
        <Row>
          {reviewsData.map((review, index) => (
            <Col lg="4" md="6" sm="12" className="mb-4" key={index}>
              <div className="review-item">
                <div className="review-icon-wrapper">
                  <i className={`review-icon ${review.icon}`}></i>
                </div>
                <div className="review-content">
                  <h5>{review.title}</h5>
                  <p>Rating: <span className="rating-value">{review.rating}</span></p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col lg="12" className="text-center mt-4 summary">
            <h5>Total Reviews: <span className="summary-value">{totalReviews}</span></h5>
            <p>Average Rating: <span className="summary-value">{averageRating}</span></p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Reviews;
