import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import "../styles/thankyou-subscription.css";

const ThankYouSubscription = () => {
  return (
    <Helmet title="Thank You for Subscribing">
      <CommonSection title="Thank You for Subscribing" />
      <section className="thankyou-subscription__section">
        <Container>
          <Row>
            <Col lg="12">
              <div className="thankyou-subscription__content">
                <h2 className="section__title">Subscription Successful!</h2>
                <p className="section__description">
                  Thank you for subscribing to our newsletter. We will keep you updated with the latest news and special offers. If you have any questions, feel free to contact us.
                </p>
                <p className="section__description">
                  Email: info.nextgenautosparts.com
                  <br />
                  Phone: +1 (844) 661-0254
                  <br />
                  Address: 30 N Gould St Ste R Sheridan, WY 82801
                </p>
                <p className="section__description">
                  We appreciate your interest in Next Gen Auto Parts and look forward to keeping you informed.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default ThankYouSubscription;
