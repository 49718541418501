const serviceData = [
  {
    id: 1,
    title: "High-Quality OEM Parts",
    icon: "ri-settings-3-line",
    desc: "We offer rigorously tested OEM parts to ensure your vehicle runs smoothly and reliably. Each part meets our high-quality standards for long-term use.",
  },

  {
    id: 2,
    title: "Affordable Prices",
    icon: "ri-money-dollar-circle-line",
    desc: "Get the parts you need without breaking the bank. We provide affordable options to help you maintain your vehicle within your budget.",
  },

  {
    id: 3,
    title: "Extensive Inventory",
    icon: "ri-database-2-line",
    desc: "Our extensive inventory includes a wide selection of hard-to-find parts. We help you locate exactly what you need for your vehicle.",
  },

  {
    id: 4,
    title: "Fast & Reliable Shipping",
    icon: "ri-truck-line",
    desc: "We offer fast and reliable shipping directly to your home, business, or mechanic. Our goal is to get you back on the road as quickly as possible.",
  },

  {
    id: 5,
    title: "Exceptional Customer Service",
    icon: "ri-customer-service-2-line",
    desc: "Our sales professionals are available 365 days a year, from 6am to 9pm CST, to assist you with orders and provide exceptional customer support.",
  },

  {
    id: 6,
    title: "Guaranteed Satisfaction",
    icon: "ri-shield-check-line",
    desc: "We stand behind the quality of our parts with a guarantee, ensuring you have confidence and peace of mind with every purchase.",
  },
];

export default serviceData;
